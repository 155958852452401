import React, { useEffect, useRef, useState } from "react";
import "../qr/qrstyles.css";
import QrScanner from "qr-scanner";
import QrFrame from "../../assets/qr/qr-frame.svg";
import apiConfig from "../../ApiConfig";

const RouteQrScan = () => {
  const scanner = useRef(null);
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qrOn, setQrOn] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [scannedUrl, setScannedUrl] = useState("");

  const onScanSuccess = async (result) => {
    const validPrefix = "https://wa.me/16178007748/?text=";

    if (result.data.startsWith(validPrefix)) {
      const extractedText = result.data.substring(validPrefix.length); // Extract everything after the prefix
      // revisar si el camion esta adentro o afuera de la planta
      const route = await fetchRouteByBravoCode(extractedText);
      
      var customMessage = '';
      if (!route.bool_in_plant) {
        customMessage = `La ${extractedText} fue ingresada a la planta.`;
      } else {
        customMessage = `La HR ${extractedText} fue sacada de la planta.`;
      }

      changeRouteStatus(route);

      setScannedUrl(customMessage);  // Set the extracted text for the modal
      setShowModal(true);  // Show the modal
    } else {
      alert("Invalid QR code. Please scan the correct code.");
    }
  };

  const onScanFail = () => {
    console.log('Scan failed.');
  };

  const fetchRouteByBravoCode = async (bravoCode) => {
    try {
      const params = {
        bravo_code: bravoCode
      }
      const response = await apiConfig.get(`/api/routes/get_route_by_bravo_code/`, { params });
      console.log('Route fetched correctly', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching route', error);
    }
  }

  const changeRouteStatus = async (route) => {
    try {
      const params = {
        bool_in_plant: !route.bool_in_plant
      }
      const response = await apiConfig.patch(`/api/routes/${route.id}/`, params);
      console.log('Route updated correctly', response.data);
    } catch (error) {
      console.error('Error updating route', error);
    }
  }

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined,
        maxScansPerSecond: 1,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn)
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);

  return (
    <div className="qr-reader">
      <video ref={videoEl}></video>
      <div ref={qrBoxEl} className="qr-box">
        <img
          src={QrFrame}
          alt="Qr Frame"
          width={256}
          height={256}
          className="qr-frame"
        />
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>QR Code Scanned</h2>
            <p>
                {scannedUrl}
            </p>
            <button onClick={() => setShowModal(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RouteQrScan;