import { useEffect, useState } from "react";
import apiConfig from "../../ApiConfig";
import routes from "../../routes";
import RouteQrScan from "../qr/RouteQrScan";

const RouteList = () => {
    const [showQrScan, setShowQrScan] = useState(false);
    const [routesWaitingOutsidePlant, setRoutesWaitingOutsidePlant] = useState([]);

    useEffect(() => {
        // fetchRoutesWaitingOutsidePlant();
        const interval = setInterval(fetchRoutesWaitingOutsidePlant, 1000); // Poll every 5 seconds
        return () => clearInterval(interval);
    }, []);

    const fetchRoutesWaitingOutsidePlant = async () => {
        try {
            const response = await apiConfig.get('/api/routes/get_routes_waiting_outside_plant/');
            setRoutesWaitingOutsidePlant(response.data);
            console.log('Routes fetched correctly');
        } catch (error) {
            console.error('Error fetching routes', error);
        }
    };

    return (
        <div>
            <h1>Lista de HRs que están en Bravo</h1>
            {routesWaitingOutsidePlant.length > 0 ?
                <table>
                    <thead>
                        <tr>
                            <th>HR</th>
                            <th>Cliente</th>
                        </tr>
                    </thead>
                    <tbody>
                        {routesWaitingOutsidePlant.map((route) => (
                            <tr key={route.id}>
                                <td>{route.bravo_code}</td>
                                <td>{route.client}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                :
                <p>No hay HRs en Bravo...</p>
            }
            {/* Toggle Button for RouteQrScan */}
            <button onClick={() => setShowQrScan(!showQrScan)}>
                {showQrScan ? 'Ocultar QR Scan' : 'Mostrar QR Scan'}
            </button>

            {/* Conditionally Render RouteQrScan */}
            {showQrScan && <RouteQrScan />}
        </div >
    );
};

export default RouteList;